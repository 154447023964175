.sidebar {
    background-color: #022229;
    width: 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar hr {
    height: 1.5px;
    background-color: #107479;
    border: none;
    margin-top: 0;
    margin-bottom: 2rem;
    width: 80%;
}

.sidebar .items {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .items li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5625rem;
    height: 3.3125rem;
    border-radius: 5px;
    cursor: pointer;
}

.sidebar .items li.active, .sidebar .items li:hover {
    background: rgba(119, 204, 208, 0.2);
}


.sidebar .logodiv {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.sidebar .logo {
    margin-top: 1.75rem;
    margin-bottom: 1.4375rem;
}

.sidebar .bottom {
    margin-top: auto;
    width: 100%;
}