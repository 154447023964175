.box.downloadbox {
    padding: 45px 35px;
}

.downloadbox .download {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.downloadbox .download .firstsection {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
    font-style: normal;
}

.downloadbox .download .firstsection svg {
    margin-right: 30px;
}

.downloadbox .download .firstsection h3 {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 4px;
}

.downloadbox .download .firstsection h4 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin: 0;
}

.downloadbanner {
    left: 0;
    right: 0;
}

.downloadbanner .buttoncontainer {
    width: 32.875rem;
    margin: 0 auto;
}

.dlload {
    animation: spin 1s linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}