/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 15px;
    font-weight: 700;
    height: 56px;
    align-content: center;
}


.accordion__button:after {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 36px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    float: inline-end;
    color: #cdcdcd;
}

.accordion__button[aria-expanded='true']::after,
.accordion__button[aria-selected='true']::after {
    transform: rotate(-135deg);
}

[hidden] {
    display: none;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.full-width{width: 100%}
.accordion .field{
    margin-bottom: 29px;
}
