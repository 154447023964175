.csform {
    width: 27.875rem;
}

.field {
    margin-bottom: 3.125rem;
}

label.label {
    display: block;
    width: 100%;
    margin-bottom: 0.8125rem;
    font-family: Inter;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #3A3A3A;
}
.csform input {
    width: 100%;
}

.csform .buttons {
    display: flex;
    margin-bottom: 14rem;
}

.review.banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
}

.review.banner .bannercontent {
    width: 32.875rem;
    display: flex;
    flex-direction: row;
}

.remindercard {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.375rem 1.1875rem;
    margin-top: 2.1875rem;
}

.remindercard .bold, .field .bold {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}

.field .bold {
    font-size: 15px;
}

hr.full {
    height: 1px;
    background-color: var(--gray3);
    border: none;
    width: 100%;
}

hr.full1 {
    margin-top: 2.125rem;
    margin-bottom: 2.6875rem;
}

hr.full2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.remindercard .text, .field .text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: var(--gray);
    margin-bottom: 6px;
}

.remindercard .bold.mb, .field .bold.mb {
    margin-bottom: 2.0625rem;
}

.reviewAlert {
    display: flex;
}

.reviewAlert svg {
    min-width: 1.5rem;
    margin-right: 0.6875rem;
}

span.required {
    color: #E44F4F;
}

.newrow{
    display: flex;
    justify-content: start;
}
.newrow .col.col-12{
    width: 100%;
}

.newrow .col.col-6{
    width: 50%;
}