.page .tophead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.page .tophead .resources-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 18px;
    font-family: 'Asap', sans-serif;
    font-weight: 600;
}

.page .tophead .resources-link a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page .tophead .resources-link .divider {
    border-left: #E0E0E0 2px solid;
    padding-left: 18px;

}

.page .tophead h2.title {
    margin-bottom: 0;
    padding-right: 19px;
}

.samples .header .rowcontent {
    padding-top: 8px;
}

.samples .rowcontent div.th, .samples .rowcontent div.td {
    width: calc(100% / 5);
}

.samples .rowcontent div.th.w-100, .samples .rowcontent div.td.w-100  {
    width: 100px;
}

.samples .rowcontent div.th.narrow, .samples .rowcontent div.td.narrow {
    width: calc(100% / 10);
}

.samples .rowcontent div.th:first-of-type, .samples .rowcontent div.td:first-of-type {
    padding-left: 2.125rem;
}

.samples .rowcontent div.td:first-of-type {
    font-weight: bold;
}

.samples .rowcontent div.td {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    display: flex;
    align-items: center;
    padding-right: 1rem;
}

.samples .rowcontent div.th {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #2C2A2E;
    padding-right: 1rem;
    display: flex;
    align-items: center;
}

.samples .rowcontent div.th.pointer {
    cursor: pointer;
}

.samples .hascheck .rowcontent div.th, .samples .hascheck .rowcontent div.td {
    width: calc(100% / 7);
}

.paginator .rowcontent {
    justify-content: flex-end;
    padding: 0.75rem 0;
}

.samples .text-center {
    text-align: center;
    width: 100%;
}

.samples .rows {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
}

.samples .row.full {
    flex-grow: 1;
}

.samples .rows.rmb {
    margin-bottom: 70px;
}

.samples .rows::-webkit-scrollbar {
    width: 3.64px;
}

.samples .rows::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.samples .rows::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.samples .last {
    flex-grow: 1;
}

