.courierroutes {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: 'Inter', sans-serif;
}

.courierroutes .topbar {
    height: 4.125rem;
    background-color: #044554;
    display: flex;
    align-items: center;
    justify-content: center;
}

.courierroutes .crcontent {
    width: 23rem;
    margin: auto;
    max-width: 100%;
    z-index: 10;
}

.courierroutes .crheader {
    height: 10.5rem;
    display: flex;
    align-items: center;
}

.courierroutes .crheader h1 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.5625rem;
    color: #044554;
    margin-bottom: 0.625rem;
}

.courierroutes .crheader .date {
    font-weight: 600;
    font-size: 1.0625rem;
    line-height: 1.125rem;
    color: #000000;
}

.courierroutes .locations {
    margin-bottom: 70px;
}

.courierroutes .locations h2 {
    font-weight: bold;
    font-size: 1.1875rem;
    line-height: 1.4375rem;
    color: #000000;
    margin-top: 1.375rem;
    margin-bottom: 1.375rem;
}

.courierroutes .crfooter {
    margin-top: 6.6875rem;
    padding: 2rem 7.1875rem 3.8125rem 7.1875rem;
    background: #F4F4F4;
}

.courierroutes .crfooter p {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: #4E4E4E;
}

.courierroutes .crfooter .contact {
    display: flex;
    align-items: center;
}

.courierroutes .crfooter .contact svg {
    margin-right: 6px;
}

.courierroutes .notes h4 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 13px;
}

.courierroutes .notes p {
    padding: 20px 18px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    white-space: break-spaces;
}

@media screen and (max-width: 760px) {
    .courierroutes .crcontent {
        width: auto;
    }
    .courierroutes .crfooter {
        padding: 2rem;
    }
}