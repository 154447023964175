.container {
    display: flex;
    height: 100vh;
}

.container-full {
    display: flex;
}

.page {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.page .content {
    padding: 1.2rem 1.9rem;
    height: calc(100% - 2.4rem);
}

.page .content .title {
    margin-top: 0;
    margin-bottom: 1.75rem;
}

.page .center {
    text-align: center;
    padding: 1rem;
}

.content-full {
    width: 100%;
    height: 100%;
    position: relative;
}

.content-full .cancel {
    position: absolute;
    right: 2.25rem;
    top: 2rem;
}

.content-full .closeBtn {
    border: none;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

span.sample_id {
    color: var(--gray2);
}

.content-full hr {
    background-color: #E0E0E0;
    height: 1px;
    width: 95%;
    border: none;
    margin-top: 0;
    margin-bottom: 0;
}

.page .content-full .title {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.content-full .full-content {
    width: 32.875rem;
    margin: 0 auto;
}

.fixed-header {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 300;
}

.fixed-header + .full-content {
    padding-top: 8rem;
}


.pb-3{
    padding-bottom: 220px;
}

.pt-1{
    padding-top: 38px;
}