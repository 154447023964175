.navbar li.pointer {
    cursor: pointer;
}

.navbar li {
    background: var(--white);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: var(--blue);
    padding: 1rem 1.4375rem;
    border-radius: 4px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 5.4375rem;
    margin-right: 3px;
}

.navbar li.active, .navbar li.pointer:hover {
    background: var(--blue);
    color: var(--white);
}

.navbar li span.tablabel.margin {
    margin-right: 0.5625rem;
}

.navbar li span.badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--red);
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-align: center;
    color: #FFFFFF;
    padding: 4px;
    min-width: 1rem;
    height: 1rem;
}