.sharingresults .checkbox {
    margin-bottom: 0.75rem;
}

.sharingresults input.other {
    width: 27.875rem;
}

.box .analysis {
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #4E4E4E;
    margin-bottom: 0.375rem;
}

.sharingresults .warning {
    padding: 20px;
    margin-bottom: 38px;
    background: #E4F5F6;
    border: 1px solid #309A9F;
    display: flex;
}

.sharingresults .warning svg {
    margin-right: 12px;
}

.entry-content {
    padding-top: 56px;
    font-family: 'Inter', sans-serif;
    overflow: hidden;
}

.entry-content .field {
    margin-bottom: 31px;
}

.full {
    width: 100%;
}

.has-error input {
    border-bottom: 2px solid red;
}

.has-error .error-message {
    color: red;
    padding-top: 6px;
}

.sample-header {
    z-index: 1000;
    height: 86px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(11.1px);
    -webkit-backdrop-filter: blur(11.1px);
}

.sample-header .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    padding: 12px;
}

.sample-header .sample-id {
    text-align: center;
    font-weight: 700;
    font-size: 32px;
    color: #373535;
    font-family: 'Asap', sans-serif;
    margin: auto;
    width: 75%;
}

.result-page-content {
    margin-top: 120px;
    margin-left: 40px;
    width: 835px;
    background: white;
    border-radius: 8px;

    border: solid #E0E0E0;

    -webkit-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
}

.service-user-inner {
    width: 450px;
    margin: auto;
}

.sample-result .page {
    position: relative;
    top: -200px;
}

.sample-result .page .sample-view-content {
    padding: 21px 21px 0;
}

.sample-result .page .sample-view-content .row3col {
    justify-content: space-between;
}

.sample-result .page .sample-view-content .row3col div.field {
    width: calc(100% / 2);
    margin-bottom: 0;
}

.sample-result .page .sample-view-content .sample-section {
    border: 2px solid #E0E0E0;
    border-radius: 8px;
    padding: 23px;
    margin-bottom: 23px;
}


.section-devider {
    margin-bottom: 36px;
}


.noteworthy {
    color: #e44f4fff;
}

.btn.access-another-btn {
    width: 180px;
}

.service-user-inner .button {
    padding-top: 73px;
}

.sample-section .title h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 46px;
}

.cta {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 0.9375rem;
    border-left: 0.5625rem solid #044554;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.cta p {
    margin: 0;
}

.accordion {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 36px;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.sample-view-content .terms-of-service {
    margin-bottom: 0;
}

.mt-2 {
    margin-top: 29px !important;
}

.h-pad {
    display: flex;
    justify-content: center;
}

.thumb-down {
    padding-left: 16px;
}

.feedback-desc {
    width: 419px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
}

.pb-1 {
    padding-bottom: 13px;
}

.hide-desktop {
    display: none;
}

.sample-result hr {
    border: 1px solid rgba(0, 0, 0, 0.1);

}

.feedback {
    width: 700px;
    display: flex;
    justify-content: space-between;
    justify-items: center
}

.technology-title, .drugs-header {
    display: flex;
    justify-content: space-between;
}

.info-link a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #309A9F;
}

.technology-title .technology-title .field {
    margin-bottom: auto;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 853px) {
    .fixed-header {
        top: 0;
    }

    .mobile-only {
        display: block;
    }

    .full-content {
        margin: 20px !important;
        width: 90% !important;
    }

    form.sharingresults {
        margin-top: 38px;
    }

    form.sharingresults .checkbox {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 16px 19px;
        margin-top: 4px;
    }

    header #branding {
        padding: 0;
        height: 281px;
        background-size: auto;
        background-position: -134px 56px;
    }

    .hide-mobile {
        display: none;
    }

    .sample-header .button .btn {
        width: 38px;
        height: 38px;
        min-width: 38px;
        padding: 0;
        margin: 2px 10px 0 0;
    }

    .sample-header .sample-id {
        text-align: center;
        font-weight: 700;
        font-size: 26px;
        color: #373535;
        font-family: 'Asap', sans-serif;
        margin: auto;
        width: 80%;
    }

    .sample-header {
        height: 66px;
    }
    .sample-result .page .sample-view-content .row3col div.field {
        width: 100%;
        margin-bottom: 0;
    }
    .withscroll {
        overflow-y: auto;
        padding-right: 10px;
        height: 85%;
    }
    .result-page-content {
        margin-top: 136px;
        margin-left: auto;
        width: 100%;
        background: none;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    .sample-result .page {
        position: relative;
        top: -341px;
    }

    .sample-result .page .sample-view-content .sample-section {
        background: white;
    }

    .sample-result .page .sample-view-content .sample-section .row3col {
        display: inline;
    }

    .sample-result .technology-title {
        display: inline;
    }

    .info-link a {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 24px;

    }
    .tc-content .info-link a {
        background: none;
        align-items: start;
        padding: 0;
    }

    .show-mobile {
        display: block;
    }

    .nodrugs, .drugrow {
        box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 16px 19px;
        margin-top: 4px;
        flex-direction: column;
    }

    .w-100 {
        width: 100% !important;
    }

    .nodrugs-mobile {

        font-family: 'Inter', sans-serif;
        color: #000000;
        margin-bottom: 2rem;
        font-weight: 700;
        font-size: .9375rem;
        line-height: 1.125rem;
    }

    .drugs-header .text {
        margin-bottom: 7px;
    }

    .drug-name {
        padding: 0;
        width: 100%;
        margin: 5px 0 0 0;
        float: left;
    }
    .drugslist .list{
        padding-bottom: 130px;
    }
    .drug-name .padding-drug{
        padding: 10px;
    }

    .drugrow .quantity, .drugrow .percentage, .drugrow .list {
        float: left;
    }

    .terms-of-service {
        border: 2px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        margin: 20px;
    }
    .terms-of-service .tc-content{
        display: flex;

    }
    .feedback {
        width: 100%;
        display: block;

        text-align: center;

    }
    .feedback-container{
        background: #f4f4f4;
        padding: 20px;
        margin: -23px;
        border-radius: 0 0 8px 8px;
    }
    .feedback-desc {
        width: 100%;
        font-size: 17px;

    }
    .service-user-inner{
        width: initial;
    }

}

.sample-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 15px 0;
    background: #f4f4f4;
    margin-top: 5px;
}
