.select {
    border-radius: 4px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid #5E5E5E;
    background-color: var(--gray4);
    padding: 1rem 1.1875rem;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    cursor: text;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    height: 3.225rem;
    font-weight: 400;
}

.select .chevron {
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.selectarea {
    position: relative;
    width: 100%;
}

.selectarea .items {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    background-color: #F5F5F5;
    max-height: 16.4rem;
    overflow-y: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.selectarea .items .item {
    cursor: pointer;
    padding: 1rem;
}

.selectarea .items .item:hover, .selectarea .items .item.active {
    background: var(--gray3);
}

.selectarea .items::-webkit-scrollbar {
    width: 3.64px;
}

.selectarea .items::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.selectarea .items::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.select .select_text {
    border: none;
    outline: none;
    padding: 0;
}

p.select-error {
    color: #e44f4f;
    margin-top: 2px;
}

.selectarea .placeholder{
    color: #00000080;
}