h2 {
    line-height: 2.85rem;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #373535;
}

.entry-title {
    font-size: 48px;
    font-family: 'Asap', sans-serif;
    font-weight: 700;
    color: #044554;
}

.entry-content, footer {
    font-family: 'Roboto', sans-serif;
    color: #373535
}

#site-title {
    font-family: 'Asap', sans-serif;
    font-weight: 700;
    font-size: 36px;
    padding-right: 50px;
    width: 100%;
    text-align: right
}

#site-title a {
    text-decoration: none;
    color: #044554;
    line-height: 1.1;
}

#site-title a:hover {
    text-decoration: underline;
}

#branding {
    background-image: url('https://drugchecking.community/wp-content/themes/drugcheck/images/hero-bg.jpg');
    background-size: cover;
    background-position: right;
    padding: 50px 0 30px;
    text-align: right;
}

.max-w {
    max-width: 920px;
    margin: 0 auto;
}


ul.social {
    float: left;
    margin-left: 20px;
}.footer-ctas {margin-top:20px;}
.footer-ctas a, .footer-ctas li {float:left; list-style: none}
.footer-content {max-width:340px;}
ul.social {float:left;margin-left:20px;}
ul.social li a {height: 33px;width: 33px;margin: 1px 5px 0;color: #333333;transition: color .2s;display:inline-block; float:left;}
ul.social li a svg {width:100%;height:100%;fill:#373535;}
.footer-logo {margin-top:50px;}
.footer-logo svg{width:132px;margin-left: 0;}

.contact-info {
    margin-top: 60px;
}

.post-meta {margin-top:30px;}
.post-meta a{margin-top:30px;color:#373535;}
.download-cta {font-weight:bold;}
.social-media-icons {display:block;}
.social-media-icons a { width: 33px; height: 33px; display: inline-block; margin-right: 7px; margin-top: 3px; position: relative; top: 8px; }
.social-media-icons a svg{width:100%;height:100%;fill: #373535;}


.entry-content li:last-child {margin-bottom:0;}
div.cap p:first-child {margin-top: 0;}
div.cap {margin-top:30px;}
.entry-content img {width:100%;height:auto;display:block;}
.entry-content img.border {border:2px solid #d4d4d4;}
.entry-content .tableauPlaceholder {margin-top:30px;border:2px solid #d4d4d4;width:100% !important;}
.entry-content .desktop-only {display:none;}
.entry-content .tableauPlaceholder + script + p {margin-top:80px;}
.table-wrap {margin-top:80px;margin-left: -20px; width:calc(100% + 40px);overflow-x:auto;}
.table-pad {padding:0 20px;}
.table-wrap .col {float:left;padding-right:40px;}
.table-wrap .col-4 {padding-right:0;}
.table-row {padding:30px 0; width:100%;min-width:675px;font-size:16px;line-height:1.4;}
.table-row:nth-child(odd){background:#FAEFE8;}
.table-header {padding:40px 0;font-size:18px;font-weight:700;color:#044554;}
.col-1 {width:150px;}
.col-2 {width:130px;}
.col-3 {width:100px;height:1px;}
.col-4 {width:360px;}
.note-icon {width:30px;height:30px;background-image:url('https://drugchecking.community/wp-content/themes/drugcheck/images/note-icon.png');background-size:30px;background-repeat:no-repeat;}
.col .note-icon {text-indent:-10000px;margin-left:20px;}
.entry-content .col span {padding:0;}


.post-ex {margin-top:60px;}
.post-ex time {font-size:24px;font-weight:bold;}
.post-ex h2 a:hover {text-decoration:underline;}
.post-ex p {margin-top:30px;color:#044554;}
.post-ex a {margin-top:30px;}
.sticky-pin {position:absolute;width:40px;height:40px;margin-top:-22px;right:15px;border-radius:100%;top:0;background-image:url(https://drugchecking.community/wp-content/themes/drugcheck/images/pin-icon.png);background-size:40px;}

footer {
    padding: 50px 26px 50px 26px;
    background: #f4f4f4;
    margin-top: 50px;
}

footer p {
    margin-bottom: 10px;
}
footer p:last-child {margin-bottom:0;}
.footer-ctas {margin-top:20px; display: flex; align-items: center}
.cta2 {
    border-radius: 5px;
    background-color: #044554;
    text-decoration: none;
    color: #fff;
    padding: 7px 10px;
    display: inline-block;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
}
.footer-ctas a, .footer-ctas li {float:left;}
.footer-content {max-width:340px;}
ul.social {float:left;margin-left:20px;}
ul.social li a {height: 33px;width: 33px;margin: 1px 5px 0;color: #333333;transition: color .2s;display:inline-block; float:left;}
ul.social li a svg {width:100%;height:100%;fill:#373535;}
.footer-logo {margin-top:50px;}
.footer-logo svg{width:132px;margin-left: 0;}
/* .footer-logo-header{margin-bottom: 10px;} */

.footer-logos { display: flex; flex-wrap: wrap; gap: 20px; align-items: baseline;    padding-top: 20px;}


/* .cdpe-logo{padding-top: 20px}  */


.contact-info {margin-top:60px;}
@media screen and (min-width: 400px) {

    .post-bg {padding:30px 0;margin-left:-30px;width:calc(100% + 60px);}
    .entry-content span {padding:30px;}
    .table-wrap { margin-left: -30px; width:calc(100% + 60px);}
    .table-pad {padding:0 30px;}
    .sticky-pin {right:25px;}
    .manual-pager-wrap {font-size:18px;}
    .manual-pager .next:before {top:2px;}
    .manual-pager .prev:before {top:2px;}
    nav li {margin-right:9px;}
    nav li:last-child {margin-right:0;}
    footer {font-size:18px;}
    .table-row {font-size:18px;}
    .table-header {font-size:24px;}
    .col-1 {width: 150px; padding-right: 0 !important;}
    .col .note-icon {margin-left:25px;}

}

@media screen and (min-width: 853px) {
    .entry-title {
        font-size: 30px;

    }

    #branding {
        padding-top: 111px;
        padding-bottom: 60px;
    }

    .footer-logo svg {
        width: 132px;
        margin-bottom: 50px;
    }

    .footer-logos {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        align-items: baseline;
    }

    .footer-logo svg:first-child{margin:0}


    .download-cta,time,.share-cta {display:inline-block;}
    .download-cta + .share-cta:before,
    time + .share-cta:before,
    time + .download-cta:before {content:" | ";padding:0 5px;font-weight:300;}
    .download-cta + .share-cta,
    time + .share-cta,
    time + .download-cta {margin-top:0;}
    .social-media-icons {display:inline-block;}
    .social-media-icons a {display: inline-block; margin-right: 0; margin-left:7px; margin-top:0;}
    .entry-content .desktop-only {display:block;}
    .entry-content .mobile-image {display:none;}

    .post-bg {padding: 40px 0;border-radius:5px;}
    .sticky-pin {left:-24px;right:auto;transform: rotate(-30deg);}

    .col-4 {width:380px;}

    .footer-content {width:50%;     padding-right: 35px;}
    .footer-layout {display:flex;justify-content: space-between;}
    .footer-logo { margin-top: 0; align-content: center;
        /* width: 50%;  */
        display: flex; justify-content: start; align-items: start; flex-direction:column;
        position: relative;
        padding-left: 35px;
        /* top: 16px; */
    }


}

@media screen and (min-width: 800px) {
    .col-4 {width:430px;}
}

@media screen and (min-width: 920px) {

    /* mobile menu */
    .js #menu {display: block;margin-top:0;}
    .js #menu {position: relative; width: 100%; background: #fff; border: 0; padding: 0; left: -10px;right:0;}
    .js #toggle {display: none;}
    #toggle {display: none;}
    #menu {position:relative;}
    nav li {float:left;}
    nav li.current-menu-item a {color:#fff;background-color:#044554;}
    nav li.current-menu-item a:hover {text-decoration:none;}
    nav li a {color:#044554;}
    .menu-title{display:none;}
    .footer-logo svg:nth-child(2){margin-bottom:0}
    .footer-logos{flex-direction: row; flex-wrap: unset; gap: unset; align-items: end;}


    .cdpe-logo:nth-child(2){margin-left: 35px}
    .cdpe-logo:first-child{margin: 0;}

}

@media screen and (min-width: 940px) {
    .col-4 {width:530px;}
}

p a, ul a, ol a {
    color: #EE6C82;
    font-weight: 700;
}

@media only screen and (max-width: 853px) {
    .service-user-inner {
        padding: 26px;
        margin: auto;
    }

}