.row {
    width: 100%;
    background: var(--white);
    padding: 0;
    margin-bottom: 3px;
    display: flex;
    flex-direction: column;
}

.row.border-0 {
    border-radius: 0.5rem;
}

.row.border-1 {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.row.border-2 {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.row.full .rowcontent {
    min-height: 48vh;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #828282;
}

.row.pointer {
    cursor: pointer;
}

.row .rowcontent {
    padding: 0.9375rem 1.125rem;
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;
}

.row.selected {
    background-color: #E4F5F6;
    border-bottom: 3px solid #CAE9EB;
}
