.toggleswitch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggleswitch svg {
    cursor: pointer;
    margin-right: 0.6875rem;
}

.toggleswitch span {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}