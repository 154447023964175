.reportdata .content-full .full-content {
    width: 69rem;
}
.field h6 {
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
    margin-bottom: 2.0625rem;
    margin-top: 60px;
}

.receiptsamples hr {
    width: 100%;
}

.coldata {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.field .details {
    margin-bottom: 2.8125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.w80 {
    width: 65%;
}

.field .details:after {
    content: '';
    display: block;
    clear: both;
}

.field .details label {
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #3A3A3A;
    margin-bottom: 0.8125rem;
    display: block;
}

.field .details div.weight {
    margin-right: 1.5rem;
    float: left;
}

.field .details input {
    max-width: 8rem;
}

.field .details input:read-only {
    outline: none;
}

.field .details div.sign {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    position: absolute;
}

div.signleft {
    left: -70%;
}

div.signright {
    right: -70%;
}

.field .details div.input {
    position: relative;
    width: 6.875rem;
    display: flex;
    align-items: center;
}

.field .details p {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.125rem;
    color: #000000;
    position: absolute;
    right: 0;
    top: 0;
}

/* Chrome, Safari, Edge, Opera */
.input input::-webkit-outer-spin-button,
.input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input input[type=number] {
  -moz-appearance: textfield;
}


.details .winp {
    position: relative;
}

.details.liquid {
    opacity: 0.6;
}

.drugfoundtable {
    border-collapse: collapse;
    box-sizing: border-box;
    width: 100%;
    margin-right: 2px;
    margin-bottom: 2px;
}

.drugfoundtable th {
    border: 1px solid #747474;
    box-sizing: border-box;
    background: #373535;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: #FFFFFF;
    padding: 0.5rem 0 0.5rem 0.5rem;
    text-align: left;
}

.drugfoundtable .rowindex {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #7F7F7F;
    text-align: center;
}

.drugfoundtable td {
    padding: 0 0.5625rem;
    border: 1px solid #E0E0E0;
    box-sizing: border-box;
}

.drugfoundtable td.entry {
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #373535;
    position: relative;
}

.drugfoundtable input.entryinput {
    border: none;
    background: none;
    padding: 0;
    height: 2.9375rem;
    outline: none;
    width: 100%;

    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #373535;
}

.drugfoundtable select {
    position: absolute;
    width: 50%;
    right: 0;
    padding: 4px;
    height: 100%;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;  
    outline: none;
}

input[type=number]:read-only {
    border-bottom: none;
}

.selectarea.whiteback .select {
    background: none;
    border-bottom: none;
}

.selectarea.whiteback .select input[type=text] {
    background: none;
    width: 100%;
}

.selectarea.mini {
    width: 26rem;
}


.tableContainer {
    overflow: auto;
    width: 100%;
}

.tableContainer::-webkit-scrollbar {
    width: 3.64px;
    height: 5px;
}

.tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 20px;
}

.tableContainer::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.tableContainer td .tcenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-bar {
    position: fixed;
    box-shadow: 0 -4px 20px 0 #00000026;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    height: 50px;
    padding: 13px;
}

.receiptsamples div.buttons {
    display: flex;
    flex-direction: row;
    margin: auto;
    align-items: center;
    width: 71rem;
}

.receiptsamples {
    margin-bottom: 120px;
}

.receiptsamples input {
    width: 26rem;
}

.btn4 {
    background: var(--white);
    color: #309A9F;
    border: 2px solid #309A9F !important;
}

.form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-header .btn {
    height: 50px;
}
.add-more{
    margin-top: 39px;
}
.long-margin{
    margin-top: 85px !important;
}
.analysis-technology-details h6{
    font-size: 17px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;

}