.samplestatus {
    display: flex;
    align-items: center;
}

.samplestatus .right {
    display: flex;
    flex-direction: column;
    margin-left: 0.625rem;
}

.samplestatus .update {
    font-family: Inter;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #767676;
}

.samplestatus p span {
    color: #767676;
}