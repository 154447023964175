.pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}

.pagination .stat {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    letter-spacing: 0.35px;
    color: #000000;
}

.pagination li {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination li.active {
    cursor: pointer;
}

.pagination li:not(last-child){
    margin-right: 1.875rem;
}