.courier-route {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border: 1px solid var(--gray3);
    box-sizing: border-box;
    border-radius: 0.625rem;
    width: 20.6875rem;
    height: 7.5625rem;
}

.courier-route .holder {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.courier-route .holder .left {
    transform: translateX(-1.1rem);
}

.courier-route .holder .right {
    text-align: right;
    transform: translateX(1.1rem);
}

.courier-route .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8125rem;
    width: 15.9375rem;
}

.courier-route .icons div.center {
    margin: auto 5px;
    display: flex;
    align-items: center;
}

.courier-route .place {
    font-family: 'Inter', sans-serif;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: var(--gray);
    margin-bottom: 2px;
}

.courier-route .site {
    font-family: 'Inter', sans-serif;;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}
