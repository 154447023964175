.sfcontainer {
    position: relative;   
    min-width: 20.375rem; 
}

.sfcontainer .sficon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.searchfield {
    background: var(--gray4);
    border-radius: 4px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 2.9375rem;
    padding-right: 1rem;
    border: none;

    color: #828282;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 2rem;
}