.custodylogs {
    font-family: 'Inter', sans-serif;
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
}

.custodylogs li:not(:first-child) {
    margin-top: 1.375rem;
}

.custodylogs li {
    display: block;
    width: 100%;
    border-bottom: 2px solid #E0E0E0;
}

.custodylogs li div {
    font-size: 0.875rem;
    line-height: 1.0625rem;
}

.custodylogs li div.status {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 0.875rem;
}

.custodylogs li div.value {
    font-weight: 500;
    color: #000000;
    margin-bottom: 0.625rem;
}

.custodylogs li div.date {
    font-style: italic;
    font-weight: 500;
    color: #000000;
    margin-bottom: 1.625rem;
}