.loginpage {
    background: white;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.loginbox {
    background: #FFFFFF;
    box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    width: 31rem;
}

.loginbox .topborder {
    max-width: 100%;
}

.loginbox .padding {
    padding: 3.4375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginbox hr {
    height: 2px;
    width: 100%;
    background-color: #E0E0E0;
    border: none;
    margin-bottom: 1.8125rem;
    margin-top: 0;
}

.loginbox p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: var(--gray);
    width: 100%;
}

.loginbox .signin {
    margin-bottom: 3.375rem;
}

.loginbox form {
    width: 100%;
}

.loginbox .field {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.8125rem;
}

.loginbox input {
    width: 100%;
}

.loginbox .button {
    display: flex;
    justify-content: center;
    margin-bottom: 3.75rem;
}

.loginpage .loginlogo {
    margin-bottom: 2rem;
}