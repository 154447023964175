.withtoggle .rowcontent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footerbanner {
    padding: 0.9375rem 1.125rem;
    background: #FFFFFF;
    box-shadow: 0px -2px 30px rgba(0, 0, 0, 0.05);
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #2C2A2E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 1.9rem;
    left: 6.6rem;
}

.samples {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.footerbanner .buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lowopac .rowcontent {
    opacity: 0.6;
}

.lowopac .rowcontent div.td:nth-of-type(2) {
    font-weight: bold;
}
