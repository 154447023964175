.sortable {
    margin-left: 4px;
    position: relative;
}

.sortable svg {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortable .popmenu {
    position: absolute;
    top: 13px;
    padding-inline-start: 0;
    list-style: none;
    min-width: 188px;
    background: #F5F5F5;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    animation: fade 0.3s;
    transform: translateX(-50%);
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sortable .popmenu li {
    cursor: pointer;
    padding: 15px 8px;
    background: #F5F5F5;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sortable .popmenu li:hover {
    background: var(--blue);
    color: var(--white);
}

.sortable .popmenu li span {
    white-space: nowrap;
    margin-right: 1rem;
}
