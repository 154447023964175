.message-banner{
    padding: 8px;

}

.message-banner .banner-content .message  {
    text-align: center;
    padding: 0.8125rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    color: #2C2A2E;
}
.message-banner .banner-content .badge {
    text-align: center;
    padding: 0 11px;
    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    height: 28px;
    background: #309A9F;
    border-radius: 18px;
}
.message-banner .banner-content{
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
