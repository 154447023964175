.modal-shadow {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.modal {
    margin-top: 0.75rem;
    padding: 1.375rem 1.625rem 1.375rem 2.5rem;
    width: 38.25rem;
    background: #FFFFFF;
    border-radius: 0.625rem;
}

.modal.show {
    transform: translateY(-100%);
    opacity: 0;
    animation: showModal 0.3s;
    animation-fill-mode: forwards;
}

.modal.hide {
    animation: hideModal 0.3s;
    animation-fill-mode: forwards;
}

@keyframes showModal {
    0% {
        transform: translateY(-100%);
        opacity: 0;  
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes hideModal {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;  
    }
}

.modal .modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: #000000;
    margin-bottom: 1.6875rem;
}

.modal .modal-title svg {
    cursor: pointer;
}

.modal .modal-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}