.checkbox {
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    color: #000000;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.checkbox.disabled {
    cursor: default;
}

.checkbox svg {
    min-width: 1.25rem;
    margin-right: 0.496875rem
}