.sampleview .content {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
}

.sampleview .sampleviewhead {
    padding: 2.125rem 1.9375rem 0 2.125rem;
    background-color: var(--white);
    height: 8.5rem;
}

.sampleviewheadtop {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.sampleviewheadtop .buttoncontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.sampleviewheadtop .buttoncontainer button:not(:last-child) {
    margin-right: 0.5rem;
}

.sampleviewhead hr {
    border: none;
    height: 1.5px;
    background-color: #E0E0E0;
    margin-top: 1.4375rem;
    margin-bottom: 0.875rem;
}

.sampleviewhead .summary {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.sampleviewhead .summary .date {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #4E4E4E;
    display: flex;
    align-items: center;
}

.sampleviewcontent {
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    font-family: 'Inter', sans-serif;
    height: calc(100% - 15.5rem);
}

.sampleviewcontent .title {
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
}

.sampleviewcontent .col {
    background: #FFFFFF;
    border-radius: 0.5rem;
    padding: 1.4375rem;
    padding-right: 4px;
    height: 100%;
}

.sampleviewcontent .left {
    width: 80%;
    margin-right: 1.4375rem;
}

.sampleviewcontent .right {
    width: 20%;
}

.withscroll {
    overflow-y: auto;
    padding-right: 1.4375rem;
    height: 85%;
}

.right .withscroll {
    height: 90%;
}

.withscroll::-webkit-scrollbar, .sampleviewcontent .col::-webkit-scrollbar {
    width: 3.64px;
}

.withscroll::-webkit-scrollbar-track, .sampleviewcontent .col::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 20px;
}

.withscroll::-webkit-scrollbar-thumb, .sampleviewcontent .col::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
}

.row3col {
    display: flex;
    flex-direction: row;
}

.row3col div.field {
    width: calc(100% / 3);
}

.withscroll hr {
    border: none;
    background-color: #E0E0E0;
    height: 2px;
    margin-bottom: 2.1875rem;
}

.withscroll .cta {
    margin-bottom: 2rem;
    max-width: 100%;
    padding: 9px 15px;
}

.withscroll .cta p {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
}

.drugdictionary {
    margin-top: 4.8125rem;
    margin-bottom: 2.5rem;
}

.drugdictionary a, .drugnotes p {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.drugnotes {
    margin-bottom: 4.75rem;
}

.drugnotes div {
    font-weight: 500;
    font-size: 13px;
    line-height: 11px;
}

.drugnotes p {
    margin-top: 0.5rem;
    max-width: 97%;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
}

.drugsfound .title {
    color: #4E4E4E;
    margin-bottom: 0.5625rem !important;
    position: relative;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.drugrow {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
}

.drugrow > div:first-child {
    width: 4.2rem;
    margin-right: 1rem;
    float: left;
}

.drugrow div:nth-child(2), .drugschart .list div {
    font-weight: normal;
    padding-right: 10px;
}

.drugrow.martop {
    margin-top: 2rem;
}

.drugslist {
    margin-bottom: 1.875rem;
}

.drugschart {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.drugschart .chart {
    margin-right: 3.75rem;
}

.drugsfound .noteworthy {
    color: #e44f4fff;
}

.sampleviewback {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sampleviewback svg {
    margin-right: 1.25rem;
    cursor: pointer;
}

.nodrugs {
    font-family: 'Inter', sans-serif;
    color: #000000;
    margin-bottom: 2rem;
    font-weight: 700;
    font-size: .9375rem;
    line-height: 1.125rem;
}

.drugInfoBox {
    width: 135px;
    background: #F4F4F4;
    border-radius: 3.99513px;
    padding: 0.2rem 0.4rem;
}

.drugInfoBox p {
    text-align: center;
    color: #4E4E4E;
    font-weight: 500;
    font-size: 8.12734px;
    line-height: 11px;
}

.postNotes, .postNotes P {
    text-align: justify;
    font-weight: bold;
    font-size: 8px;
    line-height: 140%;
}
