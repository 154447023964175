.receiptsamples .box {
    margin-top: 1.375rem;
    padding-bottom: 0;
}

.receiptsamples .box .boxrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.75rem;
}

.receiptsamples .box .boxsample {
    display: flex;
    flex-direction: column;
}

.receiptsamples .box .boxsample:not(:first-child) {
    margin-left: 1rem;
}

.receiptsamples .box .sampleidlabel {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1rem;
    color: #4E4E4E;
}

.receiptsamples .box .sampleid {
    font-weight: bold;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: #000000;
}

.beside {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.875rem;
}

.beside svg {
    margin-right: 0.875rem;
}

.beside label.label {
    margin: 0;
}

.receiptsamples div.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.box .shippingheader{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid #cdcdcd;
    padding-bottom: 18px;
    margin-bottom: 36px;
    font-weight: 700;


}

.box .shippingheader .shippingtitle{
   color: #7f7f7f;
   width:"205px"

}