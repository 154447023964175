.notification {
    display: none;
    
    position: fixed;
    width: 387px;
    height: 63px;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);

    border-radius: 6.71613px;
    color: #FFFFFF;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;

    z-index: 500;
}

.notification .icon {
    margin-left: 1.0625rem;
}

.notification .content {
    margin-left: 1.0625rem;
    color: #FFFFFF;
    width: 15.625rem;
}

.notification .content .message {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.375rem;
}

.notification .content .status {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.1875rem;
}

.notification .view {
    font-family: 'Asap', sans-serif;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 1.25rem;
    color: #FFFFFF;

    border-left: 1.11935px solid #FFFFFF;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 2rem;

    display: flex;
    align-items: center;
}

@keyframes notificationFadeIn {
    0% {
        display: none;
        opacity: 0;
    }

    100% {
        opacity: 1;
        display: flex;
    }
}

@keyframes notificationFadeOut {
    0% {
        opacity: 1;
        display: flex;
    }

    100% {
        opacity: 0;
        display: none;
    }
}

.notification.hide {
    animation: notificationFadeOut 0.5s;
    animation-fill-mode: forwards;
}

.notification.show {
    animation: notificationFadeIn 0.5s;
    animation-fill-mode: forwards;
}