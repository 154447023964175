@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --blue: #044554;
    --blue2: #309A9F;
    --blue3: #77CCD0;
    --blue4: #E4F5F6;
    --green: #AB9969;
    --green2: #ABC897;
    --red: #E44F4F;
    --red2: #EE6C82;
    --red3: #FCCAAC;
    --red4: #FBE5D7;
    --orange: #F4D166;
    --gray: #4E4E4E;
    --gray2: #7F7F7F;
    --gray3: #C4C4C4;
    --gray4: #F5F5F5;
    --black: #373535;
    --white: #ffffff;
}

body {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2.3125rem;
    color: #000000;
}

h2 {
    font-family: 'Asap', sans-serif;
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 1.875rem;
    color: #000000;
}

.subhead {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    color: #000000;
}

.text-body {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
}

.btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    border: none;
    min-width: 7rem;
    padding: 0.9375rem 1.125rem;
    border: 2px solid #4B4B4B;
    border-radius: 0.3125rem;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    text-decoration: none;
}

.btn1 {
    background: var(--black);
    color: var(--white);
}


.btn2 {
    background: var(--white);
    color: #4B4B4B;
}

button:disabled,
button[disabled]{
    opacity: 0.4;
    border-radius: 5px;
    cursor: not-allowed;
}

.btn3 {
    background: #F4F4F4;
    border-radius: 1.875rem;
    font-size: 1rem;
    line-height: 2.5rem;
    color: var(--black);
    border: none;
    padding: 0 1.3125rem;
}

.btn-clear {
    cursor: pointer;
    background-color: transparent;
    border: none; 
}

.banner {
    box-shadow: 0px -2px 1.875rem rgba(0, 0, 0, 0.05);
    padding: 0.8125rem;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #2C2A2E;
}

input[type=text], input[type=password], input[type=email], input[type=number] {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #5E5E5E;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    background-color: var(--gray4);
    box-sizing: border-box;
}

input[type=text]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text]:-ms-input-placeholder { /* 'Inter', sans-serifnet Explorer 10-11 */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text]::-ms-input-placeholder { /* Microsoft Edge */
    font-family: 'Inter', sans-serif;
    font-size: 0.9375rem;
    line-height: 1.125rem;    
    color: rgba(0, 0, 0, 0.5);
}

input[type=text].hidden {
    opacity: 0;
    width: 100%;
    height: 0;
    border: none;
    padding: 0 0 0.5px 0;
    margin: 0;
    outline: none;
    pointer-events: none;
    display: block;
}

.spzero {
    padding: 0;
    margin: 0;
}

a {
    color: var(--blue2);
}

.mr-1 {
    margin-right: 1rem;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-seven {
    margin-right: 7px;
}

.mr-3 {
    margin-right: 3rem;
}

.mb-1 {
    margin-bottom: 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

.pb-8 {
    padding-bottom: 8rem;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.field textarea {
    height: 10.5rem;
    overflow-y: auto;
    border-radius: 4px;
    background-color: var(--gray4);
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #5E5E5E;
    font-family: 'Inter', sans-serif;
    color: #000000;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.1875rem;
    box-sizing: border-box;
    resize: none;
    font-size: 0.9375rem;
    line-height: 1.125rem;
}

.ml-auto {
    margin-left: auto;
}

.tag {
    display: inline-block;
    background: #F4F4F4;
    border-radius: 1.625rem;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 0.625rem 0.875rem;
    text-align: center;
}

.box {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 0.5rem;
    padding: 1.8125rem 1.125rem;
}

.box .boxheader {
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 1.125rem;
    color: #000000;
    padding-bottom: 1.125rem;
    margin-bottom: 1.875rem;
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    align-items: flex-end;
}

.box .boxheader svg {
    margin-right: 5px;
}

.box.CAMH {
    background: #E4F5F6;
    border: 1px solid #309A9F;
}


.box.CAMH .boxheader {
    border-bottom: 1px solid #309A9F;
}


.box.SMH {
    background: #FBE5D7;
    border: 1px solid #EE6C82;
}

.box.SMH .boxheader {
    border-bottom: 1px solid #EE6C82;
}

.box.DCU {
    background: #E4DCE8;
    border: 1px solid #857590;

}

.box.DCU .boxheader{
    border-bottom: 1px solid #857590;

}

.box.DAS {
    background: #E3EFDA;
    border: 1px solid #A5C986;

}

.box.DAS .boxheader {
    border-bottom: 1px solid #A5C986;

}

.mb-2 {
    margin-bottom: 2rem;
}

.cta {
    background: #F4F4F4;
    border-radius: 8px;
    padding: 0.9375rem;
    border-left: 0.5625rem solid #044554;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.notAnalyzed {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #4E4E4E;
}

.notAnalyzed p {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    margin-top: 8px;
}

.pal {
    padding: 1rem;
}

.dfrow {
    display: flex;
    flex-direction: row;
}

.dfrow .mg {
    margin-left: 3px;
    margin-right: 10px;
}